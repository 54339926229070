import React from 'react'
import {useHistory} from 'react-router-dom'
import {
  Box,
  AspectRatio,
  Image,
  MenuItem,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'

import {PageHeader} from '../../components/PageHeader/PageHeader'
import {PageTemplate} from '../../templates/PageTemplate/PageTemplate'
import {
  ActionButton,
  BlankSlate,
  ComposeAlbumModal,
  ScrollList,
  Visible,
} from '../../components'
import {Feature} from '../../components/Feature/Feature'
import {SnowmanMenu} from '../../components/PhotoGallery/SnowmanMenu'
import {Album} from '../../types'
import {useFetchPaginatedAlbums} from '../../services/useFetchPaginatedAlbums'
import {useAlbumService} from '../../services/useAlbumService'
import {useConfirmation} from '../../app/ConfirmationManager'

import '../../assets/stylesheets/utils.css'
import {useStatusToast} from '../../app/ToastManager'
import {removeStoredPhotoState} from '../../utils'
import copy from './copy.json'
import {ComposeAlbumManager} from '../../components/ComposeAlbumModal/contextManagers/ComposeAlbumManager'
import {getScheduleText} from '../../utils/getScheduleText'

const fallbackImage =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('../../assets/images/placeholderPhoto.jpg') // TypeScript doesn't seem to like importing images, but old school requires worked

export const AlbumsView: React.FC<React.PropsWithChildren> = () => {
  const {
    status,
    data: fetchedAlbums,
    maybeFetchMore,
  } = useFetchPaginatedAlbums()
  const {getCoverPhoto, deleteAlbum} = useAlbumService()
  const showConfirmation = useConfirmation()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const history = useHistory()
  const [visibleAlbums, setVisibleAlbums] = React.useState<Album[]>([])
  const {toast} = useStatusToast()
  const [removedAlbumIDs, setRemovedALbumIDs] = React.useState<Album['ID'][]>(
    [],
  )

  React.useEffect(() => {
    setVisibleAlbums(
      fetchedAlbums.filter((album) => !removedAlbumIDs.includes(album.ID)),
    )
  }, [fetchedAlbums, removedAlbumIDs])

  const handleAlbumClick = (album: Album): void => {
    history.push(`/album/${album.ID}`)
  }

  const handleOnDeleteClick = (ID: Album['ID']) => (): void => {
    showConfirmation({
      variant: 'danger',
      title: copy.confirmations.delete.title,
      description: copy.confirmations.delete.description,
      confirmButtonText: copy.confirmations.delete.buttonConfirmation,
      testID: 'delete-album',
      onConfirm: async (): Promise<void> => {
        const response = await deleteAlbum(ID)

        if (response === 'success') {
          setRemovedALbumIDs((prev) => [...prev, ID])

          removeStoredPhotoState(ID)

          toast.success(copy.toasts.deleteSuccess)
        } else {
          toast.error(copy.toasts.deleteFailure)
        }
      },
    })
  }

  const header = (
    <PageHeader
      title='Albums'
      logContext='AlbumsView'
      description='Create albums and upload your photos. Once photos are uploaded they are added to the Campanion stream for your parents to view.'
      callToAction={
        <Feature name='create-album'>
          <ActionButton width={['100%', 'unset']} onClick={onOpen}>
            {copy.buttons.newAlbum}
          </ActionButton>
        </Feature>
      }
    />
  )

  return (
    <PageTemplate
      header={header}
      width={['100%']}
      maxWidth={['1030px']}
      borderX={['0px']}>
      <>
        <Box borderTop='1px' margin={3} />

        {!visibleAlbums.length && (
          <Feature
            name='create-album'
            render={(isEnabled: boolean) => {
              if (!isEnabled) {
                return (
                  <BlankSlate iconName='question-outline'>No Albums</BlankSlate>
                )
              }

              return (
                <BlankSlate iconName='question-outline'>
                  Create your first album.
                </BlankSlate>
              )
            }}
          />
        )}

        <ScrollList
          isLoading={status === 'pending'}
          hasError={status === 'rejected'}
          onNext={maybeFetchMore}>
          <SimpleGrid
            minChildWidth={['140px', '180px']}
            display={
              !visibleAlbums || visibleAlbums.length === 0 ? 'none' : 'grid'
            }>
            {visibleAlbums.map((album) => {
              const scheduleText = getScheduleText(album)

              return (
                <Box
                  key={album.ID}
                  padding={3}
                  data-testid='album'
                  position='relative'>
                  <Box _hover={{cursor: 'pointer'}}>
                    <AspectRatio ratio={1 / 1}>
                      <Image
                        borderRadius={3}
                        src={getCoverPhoto(album.coverPhotoID)}
                        alt={album.name}
                        objectFit='cover'
                        fallbackSrc={fallbackImage}
                        onClick={(): void => handleAlbumClick(album)}
                      />
                    </AspectRatio>
                  </Box>

                  <Feature name='edit-album'>
                    <Box
                      id='snowman-temp' // TODO: Remove
                      justifyContent='center'
                      alignItems='flex-start'
                      position='absolute'
                      right='0'
                      top='0'
                      mr={4}
                      mt={5}>
                      <SnowmanMenu>
                        <MenuItem onClick={handleOnDeleteClick(album.ID)}>
                          Delete
                        </MenuItem>
                      </SnowmanMenu>
                    </Box>
                  </Feature>
                  <Box
                    onClick={(): void => handleAlbumClick(album)}
                    _hover={{cursor: 'pointer'}}>
                    <Box
                      fontWeight='bold'
                      fontSize='15px'
                      alignSelf='flex-start'
                      className='multiline-ellipsis'
                      title={album.name}
                      paddingTop='.6rem'>
                      {album.name}
                    </Box>
                    <Box fontSize='14px' alignSelf='flex-start'>
                      {album.date.toLocaleDateString('en-US', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                      })}
                    </Box>
                    <Box
                      data-testid='album-info'
                      fontSize='14px'
                      alignSelf='flex-start'
                      color='#69747D'
                      paddingTop='4px'
                      lineHeight='18px'>
                      {album.photoCount} photos
                      <Visible when={album.constraints.length > 0}>
                        <Box>Custom Audience</Box>
                      </Visible>
                      <Visible
                        when={
                          album.photoCount > 0 || scheduleText !== 'Published'
                        }>
                        {/* Odd behavior. The schedule text can't be wrapped in
                        a block element without somehow breaking tests so we're using a breakpoint instead. */}
                        <Visible when={album.constraints.length === 0}>
                          <br />
                        </Visible>
                        {scheduleText}
                      </Visible>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </SimpleGrid>
        </ScrollList>

        {isOpen && (
          <ComposeAlbumManager>
            <ComposeAlbumModal onClose={onClose} />
          </ComposeAlbumManager>
        )}
      </>
    </PageTemplate>
  )
}
