import React, {useCallback} from 'react'
import {log} from '@vanguard/logger'
import Gallery, {GalleryProps, RenderImageProps} from 'react-photo-gallery'
import {SRLWrapper, Callbacks} from 'simple-react-lightbox'

import {GalleryImageCard} from './GalleryImageCard'

import {PhotoGalleryActions} from './types'
import {GalleryPhoto} from '../../views/AlbumView/types'

export interface Props extends GalleryProps {
  fallbackPhoto: string
  carouselPhotos: GalleryPhoto[]
  actions: PhotoGalleryActions[]
  testID?: string
}

export const PhotoGallery: React.FC<React.PropsWithChildren<Props>> = (
  props,
) => {
  const {carouselPhotos, fallbackPhoto, actions, testID} = props

  const options = React.useMemo(
    () => ({
      thumbnails: {
        showThumbnails: false,
      },
      settings: {
        hideControlsAfter: false,
      },
      buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
      },
    }),
    [],
  )

  const callbacks: Callbacks = React.useMemo(
    () => ({
      onLightboxClosed: () => {
        log.breadcrumb('Lightbox::onLightboxClosed')
      },
      onLightboxOpened: ({currentSlide}): void => {
        log.breadcrumb('Lightbox::onLightboxOpened', {
          photoIndex: parseInt(currentSlide.id, 10),
        })
      },
      onSlideChange: ({index}): void => {
        log.breadcrumb('Lightbox::onSlideChange ', {
          photoIndex: index,
        })
      },
    }),
    [],
  )

  const lightboxPhotos = Array.from(carouselPhotos, (x) => x.src)

  const imageRenderer = useCallback(
    ({margin, index, photo}: RenderImageProps) => (
      <GalleryImageCard
        key={photo.key}
        margin={margin ? parseInt(margin, 10) : 0}
        testID={testID}
        actions={actions}
        photo={photo}
        fallbackPhoto={fallbackPhoto}
        href={`${lightboxPhotos[index]}&cachebust=${Date.now().toString()}`}
      />
    ),
    [lightboxPhotos, fallbackPhoto, actions, testID],
  )
  return (
    <SRLWrapper options={options} callbacks={callbacks}>
      <Gallery {...props} renderImage={imageRenderer} />
    </SRLWrapper>
  )
}

PhotoGallery.displayName = 'PhotoGallery'
