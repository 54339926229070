import {useCallback, useMemo, useState} from 'react'

import Gallery, {GalleryProps, RenderImageProps} from 'react-photo-gallery'
import Lightbox from 'yet-another-react-lightbox'
// NOTE: Not sure why IDE thinks this isn't resolveable
// eslint-disable-next-line import/no-unresolved
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'

import {Photo} from '../../types'

import {GalleryImageCard} from '../PhotoGallery/GalleryImageCard'
import {PhotoGalleryActions} from '../PhotoGallery/types'

// NOTE: Not sure why IDE thinks this isn't resolveable
// eslint-disable-next-line import/no-unresolved
import 'yet-another-react-lightbox/styles.css'

type Props = {
  photos: Array<Photo>
  galleryPhotos: GalleryProps['photos']
  actions: PhotoGalleryActions[]
  fallbackPhoto: string
}

export const PhotoGalleryNew = ({
  photos,
  actions,
  fallbackPhoto,
  galleryPhotos,
}: Props): JSX.Element => {
  const [index, setIndex] = useState(-1)

  const lightboxSlides = useMemo(() => {
    return photos.map((photo) => ({
      type: 'image' as const,
      src: `${photo.standardURL}`,
      width: photo.standardWidth,
      height: photo.standardHeight,
    }))
  }, [photos])

  const imageRenderer = useCallback(
    ({margin, index: idx, photo}: RenderImageProps) => (
      <GalleryImageCard
        key={photo.key}
        margin={margin ? parseInt(margin, 10) : 0}
        testID='gallery-photo'
        actions={actions}
        photo={photo}
        fallbackPhoto={fallbackPhoto}
        href={`${lightboxSlides[idx].src}`}
        onClick={() => setIndex(idx)}
      />
    ),
    [lightboxSlides, fallbackPhoto, actions],
  )

  return (
    <>
      <Gallery
        photos={galleryPhotos}
        targetRowHeight={250}
        renderImage={imageRenderer}
      />

      <Lightbox
        plugins={[Fullscreen]}
        index={index}
        slides={lightboxSlides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </>
  )
}
